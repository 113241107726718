// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsby-theme-landmarks-developer-templates-modele-jsx": () => import("./../src/gatsby-theme-landmarks-developer/templates/modele.jsx" /* webpackChunkName: "component---src-gatsby-theme-landmarks-developer-templates-modele-jsx" */),
  "component---src-gatsby-theme-landmarks-developer-templates-etaj-jsx": () => import("./../src/gatsby-theme-landmarks-developer/templates/etaj.jsx" /* webpackChunkName: "component---src-gatsby-theme-landmarks-developer-templates-etaj-jsx" */),
  "component---src-gatsby-theme-landmarks-developer-templates-apartament-jsx": () => import("./../src/gatsby-theme-landmarks-developer/templates/apartament.jsx" /* webpackChunkName: "component---src-gatsby-theme-landmarks-developer-templates-apartament-jsx" */),
  "component---node-modules-gatsby-theme-landmarks-core-src-pages-404-js": () => import("./../node_modules/gatsby-theme-landmarks-core/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-landmarks-core-src-pages-404-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-js": () => import("./../src/pages/despre.js" /* webpackChunkName: "component---src-pages-despre-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-confidentialitate-gdpr-js": () => import("./../src/pages/politica-confidentialitate-gdpr.js" /* webpackChunkName: "component---src-pages-politica-confidentialitate-gdpr-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

