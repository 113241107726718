const buttonCommon = {
  textAlign: 'center',
  minWidth: ['100%', '200px'],
  cursor: 'pointer',
  borderRadius: 2.5,
  outline: 'none',
  transitionProperty: 'all',
  transitionDuration: '0.3s',
  mb: 3,
  '> svg': {
    position: 'absolute',
  }
}


const theme = {
  // breakpoints: ['40em', '64em', '80em'],
  breakpoints: ['640px', '1024px', '1280px'],
  colors: {
    primary: '#07385b',
    secondary: '#FFFFFF',
    gold: '#b67e51',
    goldDark: '#9a6b44',
    secondaryLighter: '#eaeaea',
    tertiary: '#0e6596',
    tertiaryDark: '#4B734D',
    success: '#4B734D',
    warning: '#D3B85E',
    error: '#C74C30',
    body: '#6C6C6C',
    text: '#6C6C6C',
    lightGray: 'rgba(0, 0, 0, .06)',
    lightGrayOpaque: '#F1F1F1',
    mediumGray: 'rgba(0, 0, 0, .2)',
    mediumGrayOpaque: '#D3D3D3',
    gray: 'rgba(0, 0, 0, .4)',
    darkGray: '#7E7E7E',
    dark: '#2E3948',
    white: '#fff',
    background: '#eaeaea',
  },
  fonts: {
    body: 'Auto3 LightLF',
    heading: 'Auto3 BoldLF',
    monospace: 'Auto3 LightLF'
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  radii: [0, 2, 4, 16, 32, 99],

  styles: {
    root: {
      margin: 0,
      padding: 0,
      fontFamily: 'Auto3 LightLF',
      fontSize: 2,
      lineHeight: '2rem',

      img: {
        maxWidth: '100%'
      },

      svg: {
        display: 'block'
      },

      '*': {
        boxSizing: 'border-box'
      }
    },
    spinner: {
      width: 4,
      height: 4
    },
    error: {}
  },

  //variants
  forms: {
    input: {
      transitionProperty: 'all',
      transitionDuration: '0.3s',
      borderRadius: 2,
      outline: 'none',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: theme => `0 0 0 3px ${theme.colors.primary}`
      },
      error: {
        color: 'error',
        '&:focus': {
          borderColor: 'error',
          boxShadow: theme => `0 0 0 3px ${theme.colors.error}`
        }
      }
    },
    label: {
      cursor: 'pointer',
      fontFamily: 'heading',
      error: {
        color: 'error'
      }
    },
    checkbox: {
      error: {
        color: 'error',
        svg: {
          color: 'error'
        }
      }
    },
    select: {
      borderRadius: 2
    },
    fieldError: {
      display: 'block',
      color: 'error',
      fontSize: 1
    }
  },

  badges: {
    primary: {
      px: 2,
      fontFamily: 'heading',
      textTransform: 'uppercase'
    }
  },

  funkyTitle: {
    large: {
      fontSize: '100px',
      color: 'secondary'
    },
    medium: {
      fontSize: '70px',
      color: 'secondary'
    }
  },

  floorplan: {
    apartment: {
      '& polygon': {
        transitionProperty: 'all',
        transitionDuration: '0.3s'
      },
      '& #floor': {
        fill: '#D6D6D6'
      },
      '& #terrace': {
        fill: '#868686'
      }
    },
    colors: {
      inactiv: 'darkGray',
      disponibil: 'success',
      rezervat: 'warning',
      vandut: 'error',
    }
  },

  interestPoint: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    m: 3,
  },
  text: {
    heading: {},
    small: {
      fontSize: 0,
      lineHeight: '25px'
    },
    golden: {
      color: '#868686',
      fontFamily: 'Chalet Strong',
      fontWeight: 'bold'
    }
  },

  buttons: {
    primary: {
      ...buttonCommon,
      backgroundColor: 'gold',
      color: 'white',
      fontSize: 2,
      '&:hover': {
        backgroundColor: 'goldDark'
      }
    },
    secondary: {
      ...buttonCommon,
      backgroundColor: 'secondary',
      maxHeight: '35px'
    },
    tertiary: {
      ...buttonCommon,
      minWidth: ['100%', '125px'],
      maxHeight: '30px',
      background: '#025180',
    },
    outline: {
      ...buttonCommon,
      transitionProperty: 'box-shadow',
      transitionDuration: '0.6',
      boxShadow: theme => `inset 0px 0px 0px 2px ${theme.colors.tertiary}`,
      color: 'tertiary',
      backgroundColor: 'transparent',
      '&:hover': {
        boxShadow: theme => `inset 0px 0px 0px 3px ${theme.colors.tertiary}`
      }
    },
    clear: {
      ...buttonCommon,
      backgroundColor: 'transparent',
      color: 'body',
      '&:hover': {
        backgroundColor: 'lightGray'
      }
    },
    disabled: {
      ...buttonCommon,
      backgroundColor: 'mediumGray',
      cursor: 'initial',
      '&:hover': {
        backgroundColor: 'mediumGray'
      }
    }
  },

  links: {
    default: {
      color: 'primary',
      '&: hover': {
        color: 'tertiary'
      }
    },
    inYourFace: {
      fontSize: 5,
      textDecoration: 'none',
      fontFamily: 'heading',
      p: 4,
      lineHeight: '40px'
    }
  }
}

theme.sizes.container = 1440
theme.forms.select = theme.forms.input
theme.styles.error = theme.forms.fieldError

export default theme